define("@beijer/morpheus-ide-components/components/ide/common/list-view-generic/component", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "@ember/utils", "@beijer/morpheus-ide-components/components/ide/common/list-view-generic/template"], function (_exports, _component, _computed, _object, _utils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   * @module
   * @description page
   * @augments ember.Component
   */
  var _default = _component.default.extend({
    layout: _template.default,
    hasTitle: (0, _object.computed)('title', function () {
      return !(0, _utils.isEmpty)(this.title);
    }),
    searchParam: '',
    escapedSearchParam: (0, _object.computed)('searchParam', function () {
      return this.searchParam.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }),
    pageSize: 20,
    sort: (0, _object.computed)('sortBy.{direction,field}', function () {
      return [`${this.sortBy.field}:${this.sortBy.direction}`];
    }),
    hasCheckbox: false,
    boolPropertName: 'checked',
    selectAllText: (0, _object.computed)('data.length', 'filtered.[]', function () {
      return this.filtered.length == this.data.length ? 'Select All' : 'Select Filtered';
    }),
    filtered: (0, _object.computed)('data.[]', 'escapedSearchParam', 'fields.[]', 'searchParam', function () {
      let search = new RegExp(this.escapedSearchParam, 'i');
      return this.data.filter(item => {
        for (let [key] of Object.entries(this.fields)) {
          if (search.test(item[key])) return true;
        }

        return false;
      });
    }),
    filteredAndSorted: (0, _computed.sort)('filtered', 'sort'),

    init() {
      this._super(...arguments);

      (0, _object.defineProperty)(this, 'selectedCount', _object.computed.apply(this, ['data', `data.@each.${this.boolPropertName}`, 'boolPropertName', function () {
        return this.data.filter(p => (0, _object.get)(p, this.boolPropertName)).length;
      }]));
      (0, _object.defineProperty)(this, 'allItemsSelected', _object.computed.apply(this, ['filtered.[]', `filtered.@each.${this.boolPropertName}`, 'boolPropertName', function () {
        return this.filtered.length == 0 ? false : this.filtered.filter(p => (0, _object.get)(p, this.boolPropertName)).length === this.filtered.length;
      }]));
    },

    totalCount: _object.computed.reads('data.length'),
    isSelectAllDisabled: _object.computed.equal('filtered.length', 0),
    actions: {
      onSort(sort) {
        this.set('sortBy', sort);
      },

      selectAllItems(bool) {
        this.filtered.forEach(item => {
          (0, _object.set)(item, this.boolPropertName, !bool);
        });
      },

      changeValue(pageItem) {
        (0, _object.set)(pageItem, this.boolPropertName, !(0, _object.get)(pageItem, this.boolPropertName));
      }

    }
  });

  _exports.default = _default;
});