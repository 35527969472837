define("@beijer/morpheus-ide-components/components/ide/common/radio-selector/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "k1caAYSI",
    "block": "[[[10,\"label\"],[15,0,[29,[\"radio-selector-title \",[52,[33,1],\"disabled\"]]]],[12],[1,[34,2]],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"elements\"]]],null]],null],null,[[[1,\"  \"],[10,\"label\"],[15,0,[29,[\"radio-selector-container \",[52,[33,1],\"disabled\"]]]],[12],[1,\"\\n    \"],[1,[30,1,[\"text\"]]],[1,\"\\n    \"],[10,\"input\"],[15,3,[29,[[36,5]]]],[15,1,[29,[[28,[37,6],[\"radio-selector-item_\",[30,1,[\"value\"]]],null]]]],[15,\"onchange\",[28,[37,7],[[30,0],\"radioSelectorChanged\",[30,1,[\"value\"]]],null]],[15,\"checked\",[52,[28,[37,8],[[33,9],[30,1,[\"value\"]]],null],\"checked\"]],[15,\"disabled\",[36,1]],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"checkmark\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"item\"],false,[\"if\",\"isDisabled\",\"title\",\"each\",\"-track-array\",\"groupName\",\"concat\",\"action\",\"eq\",\"model\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/radio-selector/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});