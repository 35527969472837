define("@beijer/morpheus-ide-components/components/ide/common/bjgui-checkbox/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DxHiZCe9",
    "block": "[[[10,\"label\"],[14,0,\"bjgui-checkbox__container\"],[12],[1,\"\\n\"],[8,[39,0],[[24,3,\"checkbox\"],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[17,1],[4,[38,1],[\"change\",[30,0,[\"onChange\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"isChecked\"]]]],null],[1,\"\\n  \"],[10,1],[15,0,[29,[\"bjgui-checkbox__checker \",[52,[51,[30,0,[\"isDisabled\"]]],\"enabled\"]]]],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"input\",\"on\",\"unless\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/bjgui-checkbox/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});