define("@beijer/morpheus-ide-components/components/ide/common/avatar-button/component", ["exports", "@beijer/morpheus-ide-components/components/ide/common/avatar-button/template", "@ember/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    logoutIconUrl: '',
    classNames: ['avatar-button'],
    actions: {
      login() {
        this.onLogin();
      },

      logout() {
        this.onLogout();
      },

      changeOrganization(closeDropdown) {
        closeDropdown();
        this.onChangeOrganization();
      }

    }
  });

  _exports.default = _default;
});