define("ember-cp-validations/utils/utils", ["exports", "@ember/array/proxy", "@ember/object/proxy", "@ember/polyfills", "@ember/string", "@ember/object", "@ember/utils", "@ember/array", "ember", "ember-require-module", "ember-cp-validations/-private/ember-internals"], function (_exports, _proxy, _proxy2, _polyfills, _string, _object, _utils, _array, _ember, _emberRequireModule, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unwrapString = unwrapString;
  _exports.unwrapProxy = unwrapProxy;
  _exports.isProxy = isProxy;
  _exports.isPromise = isPromise;
  _exports.isDsModel = isDsModel;
  _exports.isDSManyArray = isDSManyArray;
  _exports.isEmberObject = isEmberObject;
  _exports.isObject = isObject;
  _exports.isValidatable = isValidatable;
  _exports.getValidatableValue = getValidatableValue;
  _exports.mergeOptions = mergeOptions;
  Object.defineProperty(_exports, "getDependentKeys", {
    enumerable: true,
    get: function () {
      return _emberInternals.getDependentKeys;
    }
  });
  Object.defineProperty(_exports, "isDescriptor", {
    enumerable: true,
    get: function () {
      return _emberInternals.isDescriptor;
    }
  });
  const DS = (0, _emberRequireModule.default)('ember-data');
  const {
    canInvoke
  } = _ember.default;

  function unwrapString(s) {
    if ((0, _string.isHTMLSafe)(s)) {
      return s.toString();
    }

    return s;
  }

  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy((0, _object.get)(o, 'content')) : o;
  }

  function isProxy(o) {
    return !!(o && (o instanceof _proxy2.default || o instanceof _proxy.default));
  }

  function isPromise(p) {
    return !!(p && canInvoke(p, 'then'));
  }

  function isDsModel(o) {
    return !!(DS && o && o instanceof DS.Model);
  }

  function isDSManyArray(o) {
    return !!(DS && o && (0, _array.isArray)(o) && (o instanceof DS.PromiseManyArray || o instanceof DS.ManyArray));
  }

  function isEmberObject(o) {
    return !!(o && o instanceof _object.default);
  }

  function isObject(o) {
    return (0, _utils.typeOf)(o) === 'object' || (0, _utils.typeOf)(o) === 'instance';
  }

  function isValidatable(value) {
    let v = unwrapProxy(value);
    return isDsModel(v) ? !(0, _object.get)(v, 'isDeleted') : true;
  }

  function getValidatableValue(value) {
    if (!value) {
      return value;
    }

    if (isDSManyArray(value)) {
      return (0, _array.A)(value.filter(v => isValidatable(v)));
    }

    return isValidatable(value) ? value : undefined;
  }

  function mergeOptions() {
    let o = {};

    for (let i = arguments.length - 1; i >= 0; i--) {
      let _o = i < 0 || arguments.length <= i ? undefined : arguments[i];

      (0, _polyfills.assign)(o, isObject(_o) ? _o : {});
    }

    return o;
  }
});