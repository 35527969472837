define("ember-intl/-private/formatters/format-message", ["exports", "ember", "fast-memoize", "@ember/string", "@ember/polyfills", "@ember-intl/intl-messageformat", "ember-intl/-private/formatters/-base"], function (_exports, _ember, _fastMemoize, _string, _polyfills, _intlMessageformat, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  const {
    keys
  } = Object;
  const {
    Handlebars: {
      Utils: {
        escapeExpression
      }
    }
  } = _ember.default;

  function escape(hash) {
    if (!hash) {
      return;
    }

    return keys(hash).reduce((accum, key) => {
      if (typeof hash[key] === 'string') {
        accum[key] = escapeExpression(hash[key]);
      }

      return accum;
    }, (0, _polyfills.assign)({}, hash));
  }
  /**
   * @private
   * @hide
   */


  class FormatMessage extends _base.default {
    constructor() {
      super();
      this.createNativeFormatter = (0, _fastMemoize.default)((message, locales, formats) => {
        return new _intlMessageformat.default(message, locales, formats);
      });
    }

    format(message, options, _ref) {
      let {
        formats,
        locale
      } = _ref;
      const isHTMLSafe = options && options.htmlSafe;
      const formatter = this.createNativeFormatter(message, locale, formats);
      const escapedOptions = isHTMLSafe ? escape(options) : options;
      const result = formatter.format(escapedOptions);
      return isHTMLSafe ? (0, _string.htmlSafe)(result) : result;
    }

  }

  _exports.default = FormatMessage;
});