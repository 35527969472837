define("@beijer/ember-wizard-base/data-structures/wizardstep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WizardStep {
    constructor(label, registryType, title, subTitle) {
      this._label = label;
      this._registryType = registryType;
      this._title = title;
      this._subTitle = subTitle;
    }

    get label() {
      return this._label;
    }

    get registryType() {
      return this._registryType;
    }

    get title() {
      return this._title;
    }

    get subTitle() {
      return this._subTitle;
    }

  }

  _exports.default = WizardStep;
});