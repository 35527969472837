define("@beijer/morpheus-ide-components/components/ide/common/editable-label/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mdeYWSSh",
    "block": "[[[18,1,null],[1,\"\\n\"],[41,[30,0,[\"hasLeftIcon\"]],[[[1,\"  \"],[10,\"i\"],[15,0,[29,[\"glyphicon \",[36,2]]]],[12],[13],[1,\"\\n\"]],[]],null],[10,0],[14,0,\"content-control-section\"],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[[30,0,[\"classToAdd\"]],\" content-control-label\"]]],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n    \"],[8,[39,3],null,[[\"@type\",\"@class\",\"@autofocus\",\"@keyDown\",\"@value\",\"@change\",\"@keyUp\",\"@input\",\"@placeholder\",\"@readonly\",\"@autocomplete\",\"@maxlength\"],[\"text\",[52,[30,0,[\"hasTitle\"]],\"editable-label-input\",\"editable-label-input no-title\"],[30,0,[\"isAutoFocusEnabled\"]],[28,[37,4],[[30,0],\"keyPressed\"],null],[30,0,[\"textContent\"]],[28,[37,4],[[30,0],\"valueChanged\"],null],[28,[37,4],[[30,0],\"keyUp\"],null],[28,[37,4],[[30,0],\"inputFired\"],null],[30,0,[\"placeholder\"]],[30,0,[\"isReadonly\"]],\"off\",[30,0,[\"maxLength\"]]]],null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"if\",\"leftIcon\",\"input\",\"action\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/editable-label/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});