define("@beijer/morpheus-ide-components/components/ide/common/number-spinner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zj7tjyfB",
    "block": "[[[10,\"label\"],[15,0,[29,[\"spinner-control-title \",[52,[33,1],\"disabled\"]]]],[12],[1,[34,2]],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"spinner-control-box \",[52,[33,1],\"disabled\"]]]],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"spin-button sub\"],[16,\"disabled\",[36,1]],[24,4,\"button\"],[4,[38,3],[[30,0],\"changeNumber\",-1],null],[12],[1,\"-\"],[13],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"type\",\"value\",\"disabled\",\"key-press\",\"change\"],[\"text\",[33,5],[33,1],[28,[37,3],[[30,0],\"keyPressOnNumber\"],null],[28,[37,3],[[30,0],\"numberChanged\"],null]]]]],[1,\"\\n  \"],[11,\"button\"],[24,0,\"spin-button add\"],[16,\"disabled\",[36,1]],[24,4,\"button\"],[4,[38,3],[[30,0],\"changeNumber\",1],null],[12],[1,\"+\"],[13],[1,\"\\n  \"],[10,1],[12],[1,[34,6]],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"isDisabled\",\"title\",\"action\",\"input\",\"model\",\"postfix\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/number-spinner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});