define("@beijer/morpheus-ide-components/components/ide/common/editable-label/component", ["exports", "@ember/object/computed", "@beijer/morpheus-ide-components/components/ide/common/editable-label/template", "@ember/component"], function (_exports, _computed, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'label',
    classNames: ['editable-label'],
    classNameBindings: ['isActive:active', 'style'],
    style: 'primary',
    hasTitle: (0, _computed.notEmpty)('title'),
    hasLeftIcon: (0, _computed.notEmpty)('leftIcon'),
    isAutoFocusEnabled: false,
    active: false,
    defaultBorderColor: null,
    defaultLabelColor: null,
    textContent: (0, _computed.oneWay)('value'),
    title: 'Title',
    placeholder: 'Edit Text...',
    actions: {
      valueChanged(event) {
        if (this.onValueChanged) {
          this.onValueChanged(event.target.value);
        }
      },

      inputFired(event) {
        if (this.onInput) {
          this.onInput(event.target.value);
        }
      },

      keyUp(event) {
        if (this.onKeyUp) {
          this.onKeyUp(this.textContent, event.key);
        }
      },

      keyPressed(keypressEvent) {
        if (keypressEvent.key === 'Enter') {
          keypressEvent.currentTarget.blur();
        }
      }

    }
  });

  _exports.default = _default;
});