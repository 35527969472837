define("ember-intl/-private/formatters/-base", ["exports", "@ember/debug", "@ember/string", "@ember/array", "ember-intl/utils/links"], function (_exports, _debug, _string, _array, _links) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  const EMPTY_OBJECT = {};
  /**
   * @private
   * @hide
   */

  class FormatterBase {
    get options() {
      return (0, _array.A)();
    }
    /**
     * Filters out all of the whitelisted formatter options
     *
     * @method readOptions
     * @param {Object} Options object
     * @return {Object} Options object containing just whitelisted options
     * @private
     */


    readOptions(options) {
      if (!options) {
        return EMPTY_OBJECT;
      }

      let found = {};

      for (let key in options) {
        let normalized = (0, _string.camelize)(key);

        if (this.options.includes(normalized)) {
          found[normalized] = options[key];
        }
      }

      return found;
    }

    format() {
      throw new Error('not implemented');
    }
    /**
     * Invokes the Intl formatter methods
     *
     * @method _format
     * @param {value} Raw input value that needs formatting
     * @return {Object} Formatter options hash
     * @return {Object} Format options hash
     * @private
     */


    _format(value, formatterOptions, formatOptions, _ref) {
      let {
        locale
      } = _ref;

      if (!locale) {
        (true && (0, _debug.warn)(`[ember-intl] no locale has been set. Documentation: ${_links.default.unsetLocale}`, false, {
          id: 'ember-intl-no-locale-set'
        }));
      }

      const formatter = this.createNativeFormatter(locale, formatterOptions);
      return formatter.format(value, formatOptions);
    }

  }

  _exports.default = FormatterBase;
});