define("@beijer/morpheus-ide-components/components/ide/common/alert-box/component", ["exports", "@beijer/morpheus-ide-components/components/ide/common/alert-box/template", "@ember/component", "jquery", "@ember/object/computed", "@ember/service"], function (_exports, _template, _component, _jquery, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AlertBox = (_dec = (0, _service.inject)('alert-service'), _dec2 = (0, _computed.alias)('alertService.hasAlert'), _dec3 = (0, _computed.alias)('alertService.alertMessageText'), _dec4 = (0, _computed.alias)('alertService.alertType'), (_class = class AlertBox extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "layout", _template.default);

      _defineProperty(this, "attributeBindings", ['dataAcceptanceId:data-acceptance-id']);

      _defineProperty(this, "dataAcceptanceId", 'alert-box');

      _initializerDefineProperty(this, "alertService", _descriptor, this);

      _initializerDefineProperty(this, "hasAlert", _descriptor2, this);

      _initializerDefineProperty(this, "messageText", _descriptor3, this);

      _initializerDefineProperty(this, "alertType", _descriptor4, this);

      _defineProperty(this, "iconBaseUrl", 'assets/images');
    }

    get closable() {
      let alertType = this.alertType;
      return alertType === 'success' ? false : true;
    }

    get alertIcon() {
      let alertType = this.alertType;
      let iconBaseUrl = this.iconBaseUrl;

      switch (alertType) {
        case 'success':
          return `${iconBaseUrl}/validation-icon.svg`;

        case 'danger':
          return `${iconBaseUrl}/error-icon.svg`;

        case 'warning':
          return `${iconBaseUrl}/warning-icon.svg`;

        default:
          return null;
      }
    }

    get visible() {
      if (!this.hasAlert) {
        (0, _jquery.default)('#alert-box').fadeTo(0, 0);
        return false;
      } // fadeIn


      let fadePromise = (0, _jquery.default)('#alert-box').fadeTo(600, 1);

      if (this.alertType === 'success') {
        // fadeOut
        fadePromise.fadeTo(4000, 0, () => {
          this.onClose();
        });
      }

      return true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "alertService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasAlert", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "messageText", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "alertType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AlertBox;
});