define("@beijer/morpheus-ide-components/components/ide/common/drop-down/component", ["exports", "@ember/object/computed", "@beijer/morpheus-ide-components/components/ide/common/drop-down/template", "@ember/component"], function (_exports, _computed, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['ide-dropdown'],
    classNameBindings: ['style'],
    style: 'primary',
    filteredItemsList: (0, _computed.oneWay)('items'),
    actions: {
      selectionChanged(item) {
        this.set('value', item.value);
        this.onSelection(item);
      },

      filterItems(filterValue) {
        let items = this.items;

        if (filterValue) {
          items = items.filter(item => item.value.toLocaleUpperCase().includes(filterValue.toLocaleUpperCase()));
        }

        this.set('filteredItemsList', items);
      }

    }
  });

  _exports.default = _default;
});