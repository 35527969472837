define("@beijer/morpheus-ide-components/helpers/bjgui-acceptance-compliant", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   * @module mixin/common/bjgui-acceptance-compliant
   * @description add for component's html attribute data-id with its name
   * @augments ember.Mixin
   */
  var _default = _mixin.default.create({
    attributeBindings: ['acceptanceCompliant:data-id'],

    init() {
      this._super();

      if (this.__proto__._debugContainerKey) {
        let name = this.__proto__._debugContainerKey;
        this.set('acceptanceCompliant', name.replace('component:', '').replace('/', '__'));
      }
    }

  });

  _exports.default = _default;
});