define("ember-bootstrap/components/bs-form/element/layout/horizontal/checkbox", ["exports", "@ember/component", "@ember/template-factory", "ember-bootstrap/components/bs-form/element/layout/horizontal"], function (_exports, _component, _templateFactory, _horizontal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{this.horizontalInputGridClass}} {{this.horizontalInputOffsetGridClass}}">
    {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
      <div class="checkbox">
        <@labelComponent>
          {{yield}}
        </@labelComponent>
      </div>
      <@errorsComponent/>
      <@helpTextComponent/>
    {{else}}
      <div class="{{if (bs-eq "switch" @controlType) (if (macroCondition (macroGetOwnConfig "isBS5")) "form-check form-switch" "custom-control custom-switch") "form-check"}}">
        {{yield}}
        <@labelComponent/>
        <@errorsComponent/>
        <@helpTextComponent/>
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "kxFnCJZZ",
    "block": "[[[10,0],[15,0,[29,[[30,0,[\"horizontalInputGridClass\"]],\" \",[30,0,[\"horizontalInputOffsetGridClass\"]]]]],[12],[1,\"\\n\"],[1,\"    \"],[10,0],[14,0,\"checkbox\"],[12],[1,\"\\n      \"],[8,[30,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[30,2],null,null,null],[1,\"\\n    \"],[8,[30,3],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/layout/horizontal/checkbox.hbs",
    "isStrictMode": false
  });

  /**
  
   @class FormElementLayoutVerticalCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  class FormElementLayoutVerticalCheckbox extends _horizontal.default {}

  _exports.default = FormElementLayoutVerticalCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementLayoutVerticalCheckbox);
});