define("ember-bootstrap/components/bs-navbar/toggle", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _object, _component2, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button type="button" class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "navbar-toggler" "navbar-toggle"}} {{if this.collapsed "collapsed"}}" ...attributes {{on "click" this.handleClick}}>
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
        <span class="navbar-toggler-icon"></span>
      {{else}}
        <span class={{if (macroCondition (macroGetOwnConfig "isBS5")) "visually-hidden" "sr-only"}}>Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      {{/if}}
    {{/if}}
  </button>
  */
  {
    "id": "da5LqcbA",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[29,[\"navbar-toggle\",\" \",[52,[30,0,[\"collapsed\"]],\"collapsed\"]]]],[17,1],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"sr-only\"],[12],[1,\"Toggle navigation\"],[13],[1,\"\\n      \"],[10,1],[14,0,\"icon-bar\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"icon-bar\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"icon-bar\"],[12],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-navbar/toggle.hbs",
    "isStrictMode": false
  });

  let NavbarToggle = (
  /**
   * Component to implement the responsive menu toggle behavior in a [Components.Navbar](Components.Navbar.html)
   * component. Have a look there for examples.
   *
   * ### Bootstrap 3/4 Notes
   *
   * The inline version of the component uses the triple `icon-bar` styling for Bootstrap 3 and the
   * `navbar-toggler-icon` styling for Bootstrap 4.
   *
   * @class NavbarToggle
   * @namespace Components
   * @extends Ember.Component
   * @public
   */
  _dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class NavbarToggle extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "collapsed", _descriptor, this);
    }

    /**
     * @event onClick
     * @public
     */
    onClick() {}

    handleClick() {
      this.onClick();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "collapsed", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = NavbarToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NavbarToggle);
});