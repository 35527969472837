define("@beijer/morpheus-ide-components/services/alert-service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    alertType: null,
    alertMessageText: null,
    hasAlert: false,

    reset() {
      this.set('hasAlert', false);
      this.set('alertType', null);
      this.set('alertMessageText', null);
    },

    showWarning(alertMessage) {
      this.set('hasAlert', true);
      this.set('alertType', 'warning');
      this.set('alertMessageText', alertMessage.text);
    },

    showError(alertMessage) {
      this.set('hasAlert', true);
      this.set('alertType', 'danger');
      this.set('alertMessageText', alertMessage.text);
    },

    showSuccess(alertMessage) {
      this.set('hasAlert', true);
      this.set('alertType', 'success');
      this.set('alertMessageText', alertMessage.text);
    }

  });

  _exports.default = _default;
});