define("@beijer/morpheus-ide-components/components/ide/common/drop-down/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "m4p1ITIr",
    "block": "[[[10,0],[14,0,\"dropdown ide-dropdown\"],[12],[1,\"\\n    \"],[10,3],[14,1,\"dropdown-anchor\"],[14,6,\"#\"],[14,0,\"dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-expanded\",\"false\"],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[[36,0],\" content-control-label\"]]],[12],[1,[34,1]],[13],[1,\"\\n        \"],[10,0],[14,0,\"dropdown-input-div\"],[12],[1,\"\\n            \"],[1,[28,[35,2],null,[[\"type\",\"class\",\"placeholder\",\"value\",\"readonly\",\"disabled\"],[\"text\",\"editable-label-input ide-dropdown-input truncate-text\",\"Select...\",[33,3],\"true\",true]]]],[1,\"\\n            \"],[10,1],[14,0,\"dropdown-caret glyphicon glyphicon-triangle-bottom\"],[12],[13],[1,\"\\n            \"],[10,1],[14,0,\"dropdown-caret glyphicon glyphicon-triangle-top\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"ul\"],[14,1,\"ide-dropdown-menu\"],[14,0,\"dropdown-menu pull-right\"],[12],[1,\"\\n        \"],[10,\"li\"],[14,0,\"ide-dropdown-item\"],[12],[1,\"\\n            \"],[1,[28,[35,4],null,[[\"title\",\"leftIcon\",\"placeholder\",\"style\",\"onInput\"],[\"\",\"glyphicon-search\",\"Search...\",[33,5],[28,[37,6],[[30,0],\"filterItems\"],[[\"value\"],[[33,7,[\"value\"]]]]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[33,10]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[14,0,\"ide-dropdown-item\"],[12],[1,\"\\n                \"],[11,0],[24,0,\"ide-dropdown-item-link\"],[4,[38,6],[[30,0],\"selectionChanged\",[30,1]],null],[12],[1,\"\\n                    \"],[10,1],[14,0,\"ide-dropdown-item-link-text\"],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"item\"],false,[\"classToAdd\",\"title\",\"input\",\"value\",\"ide/common/editable-label\",\"style\",\"action\",\"target\",\"each\",\"-track-array\",\"filteredItemsList\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/drop-down/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});