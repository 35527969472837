define("@beijer/morpheus-ide-components/components/error-page/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wwwEE0Qn",
    "block": "[[[10,0],[14,0,\"error error__background text-center\"],[14,\"data-acceptance-id\",\"error_main-div\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"error__description text-large\"],[12],[1,\"Oops!\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"error__description text-medium\"],[12],[1,\"Something went wrong\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"text-small\"],[12],[1,\"Help us improve your experience by sending an error report or try again later\"],[13],[1,\"\\n    \"],[8,[39,0],[[24,0,\"error__contact-support\"],[24,\"data-acceptance-id\",\"contact-support\"]],[[\"@type\",\"@size\",\"@onClick\"],[\"primary\",\"lg\",[28,[37,1],[[30,0],\"pageRedirect\"],null]]],[[\"default\"],[[[[1,\"\\n        Contact Support\\n    \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"bs-button\",\"action\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/error-page/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});