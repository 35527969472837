define("@beijer/morpheus-ide-components/components/ide/common/glyphicon-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AQpHUN85",
    "block": "[[[10,1],[15,0,[29,[\"glyphicon \",[52,[33,1],\"disabled\"]]]],[15,\"onclick\",[36,2]],[15,\"data-acceptance-id\",[36,3]],[12],[1,\"\\n\"],[13]],[],false,[\"if\",\"isDisabled\",\"onclick\",\"data-acceptance-id\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/glyphicon-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});