define("@beijer/morpheus-ide-components/components/ide/common/dropdown-button-item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OCI3QoEY",
    "block": "[[[11,0],[16,0,[29,[\"dropdown-item dropdown-button-item__main \",[52,[30,0,[\"isDisabled\"]],\"disabled\"]]]],[24,\"role\",\"button\"],[16,\"data-acceptance-id\",[29,[[52,[30,0,[\"data-acceptance-id\"]],[30,0,[\"data-acceptance-id\"]],\"dropdown-button-item\"]]]],[4,[38,1],[[30,0],\"clicked\"],null],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"action\",\"yield\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/dropdown-button-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});