define("@beijer/morpheus-ide-components/components/ide/common/alert-box/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cOkvgdBf",
    "block": "[[[8,[39,0],[[24,1,\"alert-box\"]],[[\"@type\",\"@visible\",\"@dismissible\",\"@onDismiss\"],[[30,0,[\"alertType\"]],[30,0,[\"visible\"]],[30,0,[\"closable\"]],[28,[37,1],[[30,0],[30,0,[\"onClose\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"alertIcon\"]],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[30,0,[\"alertIcon\"]]],[15,\"height\",24],[15,\"width\",32],[14,\"alt\",\"alert-icon\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,1],[14,0,\"alert-box__text\"],[12],[1,\" \"],[1,[30,0,[\"messageText\"]]],[1,\" \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"bs-alert\",\"action\",\"if\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/alert-box/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});