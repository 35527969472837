define("@beijer/morpheus-ide-components/components/ide/common/radio-selector/component", ["exports", "@ember/component", "@beijer/morpheus-ide-components/components/ide/common/radio-selector/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['radio-selector'],
    attributeBindings: ['dataAcceptanceId:data-acceptance-id'],
    isDisabled: false,
    groupName: 'radio-selector-group-name',
    actions: {
      radioSelectorChanged(choice) {
        this.set('model', choice);
      }

    }
  });

  _exports.default = _default;
});