define("@beijer/morpheus-ide-components/components/ide/common/bjgui-avatar/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ELw0ZGCW",
    "block": "[[[1,\"\\n\"],[41,[33,1],[[[1,\"  \"],[10,\"img\"],[15,\"src\",[29,[[36,1]]]],[14,0,\"image-circle\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[1,[34,2]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"image\",\"initials\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/bjgui-avatar/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});