define("@beijer/morpheus-ide-components/components/ide/common/license-dialog/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3NsUv0zZ",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@model\",\"@open\",\"@size\",\"@submitTitle\",\"@submitButton\",\"@closeTitle\",\"@closeButton\",\"@cancelTitle\",\"@cancelButton\",\"@onResolve\",\"@onReject\"],[[28,[37,1],[[33,2],\"__modal\"],null],[28,[37,3],null,[[\"title\"],[[33,4]]]],[30,0,[\"isOpen\"]],\"lg\",[99,5,[\"@submitTitle\"]],[30,0,[\"submitButton\"]],[30,0,[\"cancelButtonText\"]],[99,6,[\"@closeButton\"]],[99,7,[\"@cancelTitle\"]],[30,0,[\"cancelButton\"]],[28,[37,8],[[30,0],\"accept\"],null],[28,[37,8],[[30,0],\"decline\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[34,9]],[1,\"\\n\"]],[]]]]]],[],false,[\"ide/common/bjgui-confirm\",\"concat\",\"componentCssClassName\",\"hash\",\"title\",\"submitButtonText\",\"closeButton\",\"cancelButtonText\",\"action\",\"licenseText\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/license-dialog/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});