define("@beijer/morpheus-ide-components/components/ide/common/sort-control/component", ["exports", "@ember/component", "@ember/object", "@ember/object/internals", "@beijer/morpheus-ide-components/components/ide/common/sort-control/template"], function (_exports, _component, _object, _internals, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   * @module
   * @description sort control
   * @param {Array} sorting - array of objects of custom sort rules, where will be set field direction: 'asc'|'desc'|null
   * @augments ember.Component
   */
  var _default = _component.default.extend({
    layout: _template.default,
    display: '',
    classNames: ['sort-control'],
    directionClass: (0, _object.computed)('sort.direction', function () {
      return `sort-control__item--${this.sort.direction}`;
    }),
    sortFieldList: (0, _object.computed)('fields', function () {
      if (this.fields) {
        return Object.entries(this.fields).map(field => _object.default.create(field[1]));
      }

      return [];
    }),
    powerSelectOptions: (0, _object.computed)('fields', function () {
      let results = [];

      if (this.fields) {
        Object.entries(this.fields).forEach(field => {
          let fieldObject = field[1];
          let fieldAsc = (0, _internals.copy)(fieldObject);
          fieldAsc.direction = 'asc';
          results.push(_object.default.create(fieldAsc));
          let fieldDesc = (0, _internals.copy)(fieldObject);
          fieldDesc.direction = 'desc';
          results.push(_object.default.create(fieldDesc));
        });
      }

      return results;
    }),

    didReceiveAttrs() {
      this._super();

      if (this.defaultSort) this._setDirection(_object.default.create(this.defaultSort), this.defaultDirection ?? 'asc');
    },

    selectAllText: 'Select All',
    actions: {
      changeDirection(field) {
        this._changeDirection(field);
      },

      setDirection(field2) {
        this._setDirection(field2, field2.direction);
      },

      checkboxChecked() {
        this.onCheckBoxChanged(this.isAllItemsSelected);
      }

    },

    _changeDirection(field) {
      this._setDirection(field, field.direction != 'asc' ? 'asc' : 'desc');
    },

    _setDirection(field, direction) {
      this.sortFieldList.filter(f => f.title != field.title).map(function (item) {
        item.set('direction', null);
      });
      let headerField = this.sortFieldList.find(f => f.title == field.title);
      headerField.set('direction', direction);
      this.set('currentSort', headerField);
      let option = this.powerSelectOptions.find(f => f.title == field.title && f.direction == direction);
      this.set('powerSelectSelectedOption', option);
      this.onSort(headerField);
    }

  });

  _exports.default = _default;
});