define("ember-bootstrap/components/bs-accordion/item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-bootstrap/utils/cp/type-class", "@ember/object/internals", "ember-bootstrap/utils/deprecate-subclassing", "ember-bootstrap/utils/decorators/arg", "@embroider/macros/runtime"], function (_exports, _component, _templateFactory, _component2, _typeClass, _internals, _deprecateSubclassing, _arg, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let
    (component (ensure-safe-component (bs-default @titleComponent (component "bs-accordion/item/title"))) collapsed=this.collapsed disabled=@disabled onClick=(fn (bs-default @onClick (bs-noop)) this.value))
    (component (ensure-safe-component (bs-default @bodyComponent (component "bs-accordion/item/body"))) collapsed=this.collapsed)
  as |Title Body|
  }}
    <div
      class="{{if @disabled "disabled"}} {{this.typeClass}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) "card"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel"}}"
      ...attributes
    >
      {{#if (has-block-params)}}
        {{yield
          (hash
            title=Title
            body=Body        )
        }}
      {{else}}
        <Title>
          {{@title}}
        </Title>
        <Body>
        {{yield}}
        </Body>
      {{/if}}
    </div>
  {{/let}}
  */
  {
    "id": "B48Cgox/",
    "block": "[[[44,[[50,[28,[37,2],[[28,[37,3],[[30,1],[50,\"bs-accordion/item/title\",0,null,null]],null]],null],0,null,[[\"collapsed\",\"disabled\",\"onClick\"],[[30,0,[\"collapsed\"]],[30,2],[28,[37,4],[[28,[37,3],[[30,3],[28,[37,5],null,null]],null],[30,0,[\"value\"]]],null]]]],[50,[28,[37,2],[[28,[37,3],[[30,4],[50,\"bs-accordion/item/body\",0,null,null]],null]],null],0,null,[[\"collapsed\"],[[30,0,[\"collapsed\"]]]]]],[[[1,\"  \"],[11,0],[16,0,[29,[[52,[30,2],\"disabled\"],\" \",[30,0,[\"typeClass\"]],\" \",[27],\" \",\"panel\"]]],[17,7],[12],[1,\"\\n\"],[41,[49,[30,9]],[[[1,\"      \"],[18,9,[[28,[37,9],null,[[\"title\",\"body\"],[[30,5],[30,6]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,5],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,8]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,6],null,null,[[\"default\"],[[[[1,\"\\n      \"],[18,9,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[5,6]]]],[\"@titleComponent\",\"@disabled\",\"@onClick\",\"@bodyComponent\",\"Title\",\"Body\",\"&attrs\",\"@title\",\"&default\"],false,[\"let\",\"component\",\"ensure-safe-component\",\"bs-default\",\"fn\",\"bs-noop\",\"if\",\"has-block-params\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/components/bs-accordion/item.hbs",
    "isStrictMode": false
  });

  let AccordionItem = (
  /**
   A collapsible/expandable item within an accordion
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItem
   @namespace Components
   @extends Ember.Component
   @public
   */
  _dec = (0, _typeClass.default)((0, _runtime.macroCondition)((0, _runtime.config)("D:\\Work\\b0a9a1d0bd00a6df\\node_modules\\ember-bootstrap").isNotBS3) ? 'bg' : 'panel', 'type'), (0, _deprecateSubclassing.default)(_class = (_class2 = class AccordionItem extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "value", _descriptor, this);

      _initializerDefineProperty(this, "type", _descriptor2, this);

      _initializerDefineProperty(this, "typeClass", _descriptor3, this);
    }

    /**
     * @property selected
     * @private
     */

    /**
     * @property titleComponent
     * @type {String}
     * @private
     */

    /**
     * @property bodyComponent
     * @type {String}
     * @private
     */

    /**
     * @property collapsed
     * @type boolean
     * @readonly
     * @private
     */
    get collapsed() {
      return this.value !== this.args.selected;
    }
    /**
     * @property disabled
     * @type boolean
     * @public
     */

    /**
     * Property for type styling
     *
     * For the available types see the [Bootstrap docs](https://getbootstrap.com/docs/4.3/components/navbar/#color-schemes)
     *
     * @property type
     * @type String
     * @default 'default'
     * @public
     */

    /**
     * Reference to the parent `Components.Accordion` class.
     *
     * @property accordion
     * @private
     */

    /**
     * @event onClick
     * @public
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "value", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _internals.guidFor)(this);
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "type", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = AccordionItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccordionItem);
});