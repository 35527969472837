define("@beijer/ember-wizard-base/components/wizard-wrapper/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OLaPU1HY",
    "block": "[[[41,[30,0,[\"registryType\"]],[[[46,[33,2],null,[[\"options\",\"onWizardFinished\",\"onWizardCancelled\"],[[30,0,[\"options\"]],[28,[37,3],[[30,0],\"finishWizard\"],null],[28,[37,3],[[30,0],\"cancelWizard\"],null]]],[[\"default\"],[[[],[]]]]]],[]],null]],[],false,[\"if\",\"component\",\"registryType\",\"action\"]]",
    "moduleName": "@beijer/ember-wizard-base/components/wizard-wrapper/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});