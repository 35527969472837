define("@beijer/morpheus-ide-components/components/ide/common/bjgui-avatar/component", ["exports", "@beijer/morpheus-ide-components/helpers/bjgui-acceptance-compliant", "@beijer/morpheus-ide-components/components/ide/common/bjgui-avatar/template", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _bjguiAcceptanceCompliant, _template, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   * @module pods/components/common/bjgui-avatar
   * @description create rounded avatar with image or automatically generated initials
   * @param {object} model - user
   * @param {string} model.name - name of user for generating initials
   * @augments ember.Component
   */
  var _default = _component.default.extend(_bjguiAcceptanceCompliant.default, {
    layout: _template.default,
    classNames: ['bjgui-avatar'],
    classNameBindings: ['getColorFromName'],
    classPrefix: 'bjgui-bg-color-',
    image: (0, _computed.oneWay)('model.image'),
    name: (0, _computed.oneWay)('model.name'),
    colorsCount: 7,
    initials: (0, _object.computed)('name', function () {
      let name = this.name;

      if (typeof name != 'string') {
        return '';
      }

      let initials = '';
      name.split(/[^\u00C0-\u1FFF\u2C00-\uD7FF\w]+/).forEach(part => {
        if (part && initials.length < 2) {
          initials += part[0];
        }
      });
      return initials.toUpperCase();
    }),
    getColorFromName: (0, _object.computed)('name', function () {
      return this.colorBackgroundClass(this.name);
    }),

    colorNumber(str) {
      if (typeof str != 'string') {
        return 0;
      }

      let number = str.split('').reduce((memo, letter) => {
        return memo + letter.charCodeAt(0);
      }, 0);
      return number % this.colorsCount;
    },

    colorBackgroundClass(numberOrString) {
      let color = numberOrString;

      if (typeof numberOrString != 'number') {
        color = this.colorNumber(numberOrString);
      }

      return `${this.classPrefix}${color}`;
    }

  });

  _exports.default = _default;
});