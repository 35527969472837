define("@beijer/morpheus-ide-components/components/ide/common/list-view/component", ["exports", "@ember/object/computed", "@beijer/morpheus-ide-components/components/ide/common/list-view/template", "@ember/component", "@ember/object", "@ember/array"], function (_exports, _computed, _template, _component, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['list-view'],
    items: (0, _array.A)(),
    searchTerm: '',
    sortFormat: 'recentlyUpdated',
    isFilteredItemsEmpty: (0, _computed.empty)('filteredItems'),
    isSortedOnAtoZ: (0, _computed.equal)('sortFormat', 'alphabetical'),
    isSortedOnMostRecent: (0, _computed.equal)('sortFormat', 'recentlyUpdated'),
    existingItems: (0, _computed.filter)('items', function (items) {
      return !items.isDeleted;
    }),
    sortedItems: (0, _object.computed)('existingItems', 'sortFormat', function () {
      let items = this.existingItems;
      return this._sortInDesiredFormat(items, this.sortFormat);
    }),
    filteredItems: (0, _object.computed)('sortedItems', 'searchTerm', function () {
      let searchTerm = this.searchTerm;
      let items = this.sortedItems;

      if (items && searchTerm) {
        items = items.filter(item => item.name.toUpperCase().includes(searchTerm.toUpperCase()));
      }

      return items;
    }),
    groupedItems: (0, _object.computed)('filteredItems', 'sortFieldNameAlphabetical', 'sortFieldNameRecent', 'sortFormat', function () {
      let results = [];
      let sortFormat = this.sortFormat;
      let filteredItems = this.filteredItems;

      switch (sortFormat) {
        case 'alphabetical':
          results = this._groupItemsAlphabetically(filteredItems, this.sortFieldNameAlphabetical);
          break;

        case 'recentlyUpdated':
          results = this._groupItemsByRecent(filteredItems, this.sortFieldNameRecent);
          break;

        default:
          return null;
      }

      return results;
    }),

    _formatDate(date) {
      date = new Date(date);
      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();
      let year = date.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    },

    _groupItemsAlphabetically(items, field) {
      let letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      return this._groupBy(items, item => {
        let letter = item[field][0].toLocaleUpperCase();
        return letters.includes(letter) ? letter : '#';
      });
    },

    _groupItemsByRecent(items, field) {
      return this._groupBy(items, item => {
        return this._formatDate(item[field]);
      });
    },

    _groupBy(list, groupFunction) {
      if (!list) return null;
      return list.reduce(function (groups, item) {
        let group = groupFunction(item);
        groups[group] = groups[group] || [];
        groups[group].push(item);
        return groups;
      }, []);
    },

    _sortItemsAlphabetically(items) {
      return items.sortBy(this.sortFieldNameAlphabetical);
    },

    _sortItemsByRecent(items) {
      return items.sortBy(this.sortFieldNameRecent).reverse();
    },

    _sortInDesiredFormat(items, sortTypeValue) {
      switch (sortTypeValue) {
        case 'alphabetical':
          return this._sortItemsAlphabetically(items);

        case 'recentlyUpdated':
          return this._sortItemsByRecent(items);

        default:
          return;
      }
    },

    actions: {
      sortItems(sortTypeValue) {
        if (!sortTypeValue) return;
        let sortFormat = this.sortFormat;
        if (sortFormat === sortTypeValue) return;
        this.set('sortFormat', sortTypeValue);
      },

      filterItems(filterValue) {
        this.set('searchTerm', filterValue);
      }

    }
  });

  _exports.default = _default;
});