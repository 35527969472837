define("ember-bootstrap/helpers/bs-type-class", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeClassHelper = typeClassHelper;
  _exports.default = void 0;

  function typeClassHelper(_ref, _ref2) {
    let [prefix, type] = _ref;
    let {
      default: defaultValue,
      outline = false
    } = _ref2;
    type = type ?? defaultValue;

    if (outline) {
      return `${prefix}-outline-${type}`;
    }

    return `${prefix}-${type}`;
  }

  var _default = (0, _helper.helper)(typeClassHelper);

  _exports.default = _default;
});