define("@beijer/morpheus-ide-components/components/ide/common/glyphicon-button/component", ["exports", "@ember/component", "@beijer/morpheus-ide-components/components/ide/common/glyphicon-button/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'a',
    isDisabled: null,

    didInsertElement() {
      this._super(...arguments);

      this.element.querySelector('span').classList.add(this.glyphicon);
    }

  });

  _exports.default = _default;
});