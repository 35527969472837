define("@beijer/ember-wizard-base/components/wizard-base/component", ["exports", "@ember/component", "@ember/object", "@beijer/ember-wizard-base/components/wizard-base/template", "@ember/runloop", "@ember/array"], function (_exports, _component, _object, _template, _runloop, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['ember-wizard-base'],
    cancelButtonAcceptanceId: 'ember-wizard_cancel',
    previousButtonAcceptanceId: 'ember-wizard_previous',
    nextButtonAcceptanceId: 'ember-wizard_next',
    currentStep: 0,
    wizardData: (0, _array.A)(),
    propertyBag: _object.default.create(),
    currentStepCompleted: false,
    nrOfSteps: _object.computed.reads('wizardData.length'),
    isLastStep: (0, _object.computed)('currentStep', 'nrOfSteps', function () {
      if (this.currentStep >= this.nrOfSteps - 1) {
        return true;
      }

      return false;
    }),
    isFirstStep: (0, _object.computed)('currentStep', function () {
      if (this.currentStep === 0) {
        return true;
      }

      return false;
    }),

    initPropertyBag() {},

    init() {
      if (this.options && this.options.step) {
        this.set('currentStep', this.options.step);
      }

      this._super(...arguments);

      if (this.options && this.options.propertyBag) {
        this.propertyBag = this.options.propertyBag;
      } else {
        this.initPropertyBag(this.propertyBag);
      }
    },

    _changeWizardStep(increment) {
      _runloop.run.scheduleOnce('afterRender', this, this.setCurrentStepValues, increment);
    },

    actions: {
      onAllowCurrentStepToComplete(allowCompletion) {
        this.set('allowCurrentStepToComplete', allowCompletion);
      },

      completeStep() {
        _runloop.run.scheduleOnce('afterRender', this, this.setCurrentStepCompleted, true);
      },

      incrementStep() {
        if (this.isLastStep) {
          this.onWizardFinished(this.propertyBag);
        } else {
          this._changeWizardStep(1);
        }
      },

      decrementStep() {
        this._changeWizardStep(-1);
      },

      cancel() {
        this.onWizardCancelled();
      }

    },

    setCurrentStepValues(increment) {
      this.set('allowCurrentStepToComplete', false);
      this.setCurrentStepCompleted(false);
      this.set('currentStep', this.currentStep + increment);
    },

    setCurrentStepCompleted(value) {
      this.set('currentStepCompleted', value);
    }

  });

  _exports.default = _default;
});