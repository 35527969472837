define("@beijer/morpheus-ide-components/components/ide/common/dropdown-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "f+wVX3+r",
    "block": "[[[10,0],[14,0,\"dropdown dropdown-button__main\"],[15,\"data-acceptance-id\",[29,[[52,[30,0,[\"data-acceptance-id\"]],[30,0,[\"data-acceptance-id\"]],\"dropdown-button\"]]]],[12],[1,\"\\n  \"],[10,\"button\"],[14,0,\"dropdown-toggle dropdown-button__button\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[14,4,\"button\"],[12],[1,\"\\n\"],[41,[30,0,[\"glyphicon\"]],[[[1,\"      \"],[10,1],[15,0,[29,[\"glyphicon \",[30,0,[\"glyphicon\"]]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"dropdown-menu dropdown-menu-right dropdown-button__menu\"],[12],[1,\"\\n    \"],[10,0],[12],[18,1,null],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/dropdown-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});