define("@beijer/morpheus-ide-components/components/ide/common/switch-box/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "68uJg+5m",
    "block": "[[[10,\"label\"],[15,0,[29,[\"switch-box-title \",[52,[33,1],\"disabled\"]]]],[12],[1,[34,2]],[13],[1,\"\\n\"],[10,\"label\"],[14,0,\"switch\"],[12],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[33,4],[33,1]]]]],[1,\"\\n  \"],[10,1],[14,0,\"slider round\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"isDisabled\",\"title\",\"input\",\"model\"]]",
    "moduleName": "@beijer/morpheus-ide-components/components/ide/common/switch-box/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});