define("@beijer/morpheus-ide-components/components/ide/common/switch-box/component", ["exports", "@ember/component", "@beijer/morpheus-ide-components/components/ide/common/switch-box/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['switch-box'],
    attributeBindings: ['dataAcceptanceId:data-acceptance-id']
  });

  _exports.default = _default;
});