define("@beijer/morpheus-ide-components/components/ide/common/bjgui-checkbox/component", ["exports", "@ember/component", "@beijer/morpheus-ide-components/components/ide/common/bjgui-checkbox/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BjguiCheckbox extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "layout", _template.default);

      _defineProperty(this, "classNames", ['bjgui-checkbox']);

      _defineProperty(this, "isChecked", false);
    }

    onChange() {}

  }

  _exports.default = BjguiCheckbox;
});