define("@beijer/morpheus-ide-components/components/ide/common/bjgui-list-with-pagination/component", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils", "@ember/array", "@beijer/morpheus-ide-components/components/ide/common/bjgui-list-with-pagination/template"], function (_exports, _computed, _component, _object, _utils, _array, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   * @module components/common/bjgui-list-with-pagination
   * @description List with pagination
   * @param {number} pageSize - number of elements per pageItems
   * @param {boolean} isLoaded - default: true
   * @param {hash} model - model for the component. Must have list property - the array of items to show with pagination
   * @augments ember.Component
   */
  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['bjgui-list-with-pagination'],
    pagingBackDataAcceptanceId: 'common_bjgui-list-with-pagination_back',
    pagingForwardDataAcceptanceId: 'common_bjgui-list-with-pagination_forward',
    pages: (0, _object.computed)('model.list.[]', 'pageSize', function () {
      if ((0, _utils.isEmpty)(this.model.list)) {
        return (0, _array.A)();
      }

      let pageSize = this.pageSize;
      let pagesCount = Math.ceil(this.model.list.length / pageSize);
      let pages = (0, _array.A)();

      for (let i = 0; i < pagesCount; i++) {
        let pageItems = this.model.list.slice(i * pageSize, (i + 1) * pageSize);
        pages.push({
          pageNumber: i + 1,
          pageItems
        });
      }

      return pages;
    }),
    isLoaded: true,
    currentPageNumber: 1,
    currentPage: null,

    didReceiveAttrs() {
      this._super();

      if (this.currentPageNumber <= this.pages.length) this.set('currentPage', this.pages.objectAt(this.currentPageNumber - 1));else {
        this.set('currentPage', this.pages.firstObject);
        this.currentPageNumber = 1;
      }
    },

    backEnabled: (0, _object.computed)('currentPage.pageNumber', function () {
      return this.currentPage.pageNumber !== 1 ? null : false;
    }),
    forwardEnabled: (0, _object.computed)('currentPage.pageNumber', 'pages.length', function () {
      return this.currentPage.pageNumber !== this.pages.length ? null : false;
    }),
    notEmpty: (0, _computed.notEmpty)('pages'),
    actions: {
      back() {
        let currentPageNumber = this.currentPage.pageNumber;

        if (currentPageNumber > 1) {
          this.set('currentPage', this.pages.objectAt(currentPageNumber - 2));
          this.currentPageNumber--;
        }
      },

      forward() {
        let currentPageNumber = this.currentPage.pageNumber;

        if (currentPageNumber < this.pages.length) {
          this.set('currentPage', this.pages.objectAt(currentPageNumber));
          this.currentPageNumber++;
        }
      }

    }
  });

  _exports.default = _default;
});