define("ember-get-config/index", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let configModulePath = `${(0, _runtime.config)("D:\\Work\\b0a9a1d0bd00a6df\\node_modules\\ember-cli-moment-shim\\node_modules\\ember-get-config").modulePrefix}/config/environment`;

  var _default = require(configModulePath).default;

  _exports.default = _default;
});