define("ember-bootstrap/version", ["exports", "ember", "@embroider/macros/runtime"], function (_exports, _ember, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerLibrary = registerLibrary;
  _exports.VERSION = void 0;
  const VERSION = (0, _runtime.config)("D:\\Work\\b0a9a1d0bd00a6df\\node_modules\\ember-bootstrap").version;
  _exports.VERSION = VERSION;

  function registerLibrary() {
    _ember.default.libraries.register('Ember Bootstrap', VERSION);
  }
});