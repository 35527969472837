define("@beijer/ember-wizard-base/components/wizard-wrapper/component", ["exports", "@ember/component", "@beijer/ember-wizard-base/components/wizard-wrapper/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    registryType: null,
    actions: {
      finishWizard(propertyBag) {
        this.applyAction(propertyBag);
      },

      cancelWizard() {
        this.cancelAction();
      }

    }
  });

  _exports.default = _default;
});