define("@beijer/morpheus-ide-components/components/ide/common/bjgui-confirm/component", ["exports", "@beijer/morpheus-ide-components/components/ide/common/bjgui-confirm/template", "@ember/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   * @module components/ide/common/bjgui-confirm
   * @description bjgui confirm window
   * used (ember-bootstrap.bs-modal-simple)[http://www.ember-bootstrap.com/api/classes/Components.Modal.html]
   * @param cancelTitle- text to display on cancel button
   * @param submitTitle- text to display on submit button
   * @param model.message - text to display
   * @param onResolve - callback on press Ok button
   * @param onReject - callback on press cancel button
   * @augments ember-bootstrap.bs-modal-simple
   */
  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['bjgui-confirm'],
    classNameBindings: ['class'],
    backdrop: false,
    backdropClose: false,
    size: 'sm',
    closeButton: true,
    cancelTitle: 'Cancel',
    cancelButton: true,
    submitTitle: 'Confirm',
    submitButton: true,
    title: 'Confirmation',
    open: true,
    imageUrl: null,
    imageAlt: null,
    details: null,
    fade: true,
    in: true,
    keyboard: true,

    init() {
      this._super(...arguments);

      this.model = this.model || {
        message: ''
      };
    },

    actions: {
      resolve() {
        this.onResolve();
      },

      reject() {
        this.onReject();
      }

    }
  });

  _exports.default = _default;
});