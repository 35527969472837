define("@beijer/morpheus-ide-components/components/ide/common/dropdown-button-item/component", ["exports", "@beijer/morpheus-ide-components/components/ide/common/dropdown-button-item/template", "@ember/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    actions: {
      clicked() {
        if (this.isDisabled) return false;

        if (this.onClick) {
          this.onClick();
        }
      }

    }
  });

  _exports.default = _default;
});