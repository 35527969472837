define("ember-bootstrap-cp-validations/components/bs-form", ["exports", "@ember/object/proxy", "ember-bootstrap/components/bs-form"], function (_exports, _proxy, _bsForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ValidatedBsFrom extends _bsForm.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, '__ember-bootstrap_subclass', true);
    }

    get hasValidator() {
      return !!this.model?.validate;
    }

    async validate(model) {
      let m = model;

      if (model instanceof _proxy.default && model.get('content') && typeof model.get('content').validate === 'function') {
        m = model.get('content');
      }

      await m.validate();

      if (!model.validations.isTruelyValid) {
        throw new Error();
      }
    }

  }

  _exports.default = ValidatedBsFrom;
});