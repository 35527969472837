define("ember-intl/-private/formatters/format-relative", ["exports", "fast-memoize", "@ember/array", "@ember-intl/intl-relativeformat", "ember-intl/-private/formatters/-base"], function (_exports, _fastMemoize, _array, _intlRelativeformat, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  /**
   * @private
   * @hide
   */
  class FormatRelative extends _base.default {
    constructor() {
      super();
      this.createNativeFormatter = (0, _fastMemoize.default)((locales, options) => {
        return new _intlRelativeformat.default(locales, options);
      });
    }

    get options() {
      return (0, _array.A)(['locale', 'format', 'style', 'units']);
    }

    format(value, options, ctx) {
      let dateValue = new Date(value);
      let formatOptions;

      if (options && typeof options.now !== 'undefined') {
        formatOptions = {
          now: options.now
        };
      }

      return this._format(dateValue, this.readOptions(options), formatOptions, ctx);
    }

  }

  _exports.default = FormatRelative;
});