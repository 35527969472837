define("ember-bootstrap/components/bs-accordion", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "ember-bootstrap/utils/deprecate-subclassing", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _component2, _deprecateSubclassing, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "accordion"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-group"}}"
    role="tablist"
    ...attributes
  >
    {{yield
      (hash
        item=(component (ensure-safe-component (bs-default @itemComponent (component "bs-accordion/item"))) selected=this.isSelected onClick=this.doChange)
        change=this.doChange
      )
    }}
  </div>
  */
  {
    "id": "ojQ2uDwW",
    "block": "[[[11,0],[16,0,[29,[[27],\" \",\"panel-group\"]]],[24,\"role\",\"tablist\"],[17,1],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"item\",\"change\"],[[50,[28,[37,3],[[28,[37,4],[[30,2],[50,\"bs-accordion/item\",0,null,null]],null]],null],0,null,[[\"selected\",\"onClick\"],[[30,0,[\"isSelected\"]],[30,0,[\"doChange\"]]]]],[30,0,[\"doChange\"]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@itemComponent\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"ensure-safe-component\",\"bs-default\"]]",
    "moduleName": "ember-bootstrap/components/bs-accordion.hbs",
    "isStrictMode": false
  });

  let Accordion =
  /**
    Bootstrap-style [accordion group](http://getbootstrap.com/javascript/#collapse-example-accordion),
    with collapsible/expandable items.
  
    ### Usage
  
    Use as a block level component with any number of yielded [Components.AccordionItem](Components.AccordionItem.html)
    components as children:
  
    ```handlebars
    <BsAccordion as |acc|>
      <acc.item @value={{1}} @title="First item">
        <p>Lorem ipsum...</p>
        <button {{on "click" (fn acc.change 2)}}>
          Next
        </button>
      </acc.item>
      <acc.item @value={{2}} @title="Second item">
        <p>Lorem ipsum...</p>
      </acc.item>
      <acc.item @value={{3}} @title="Third item">
        <p>Lorem ipsum...</p>
      </acc.item>
    </BsAccordion>
    ```
  
    In the example above the first accordion item utilizes the yielded `change` action to add some custom behaviour.
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Accordion
    @namespace Components
    @extends Glimmer.Component
    @public
  */
  (0, _deprecateSubclassing.default)(_class = (_class2 = class Accordion extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_isSelected", _descriptor, this);

      _defineProperty(this, "_isSelectedNonTracked", this.args.selected);

      _defineProperty(this, "_prevSelected", this.args.selected);
    }

    /**
     * The value of the currently selected accordion item
     *
     * @property isSelected
     * @private
     */
    get isSelected() {
      // ideally we would have used @localCopy here, but unfortunately this fails for Ember 3.16 in this special case
      // see https://github.com/pzuraq/tracked-toolbox/issues/17
      // So don't look at this, it is going to get ugly...
      this._isSelected; // just consume this to invalidate the getter when this changes

      if (this.args.selected && this._prevSelected !== this.args.selected) {
        // eslint-disable-next-line ember/no-side-effects
        this._isSelectedNonTracked = this._prevSelected = this.args.selected;
      }

      return this._isSelectedNonTracked;
    }

    set isSelected(value) {
      this._isSelectedNonTracked = value;
      this._isSelected = value;
    }
    /**
     * Action when the selected accordion item is about to be changed.
     *
     * You can return false to prevent changing the active item, and do that in your action by
     * setting the `selected` accordingly.
     *
     * @event onChange
     * @param newValue
     * @param oldValue
     * @public
     */


    doChange(newValue) {
      let oldValue = this.isSelected;

      if (oldValue === newValue) {
        newValue = null;
      }

      if (this.args.onChange?.(newValue, oldValue) !== false) {
        this.isSelected = newValue;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "_isSelected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selected;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "doChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doChange"), _class2.prototype)), _class2)) || _class;

  _exports.default = Accordion;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Accordion);
});