define("@beijer/morpheus-ide-components/components/ide/common/number-spinner/component", ["exports", "@ember/component", "@beijer/morpheus-ide-components/components/ide/common/number-spinner/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['number-spinner'],
    attributeBindings: ['dataAcceptanceId:data-acceptance-id'],
    minValue: 0,
    maxValue: 0,
    defaultValue: 0,

    init() {
      this._super(...arguments);

      let currentValue = parseInt(this.model);

      if (isNaN(currentValue)) {
        this.set('model', this.defaultValue);
      } else {
        this.set('model', this.verify(currentValue, this.minValue, this.maxValue));
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.element.querySelector('.spinner-control-box input').value = this.model;
    },

    verify(val, lowerLimit, upperLimit) {
      let currentValue = parseInt(val);

      if (isNaN(currentValue) || currentValue < lowerLimit) {
        return lowerLimit;
      }

      if (currentValue > upperLimit) {
        return upperLimit;
      }

      return currentValue;
    },

    actions: {
      numberChanged() {
        this.set('model', this.verify(this.model, this.minValue, this.maxValue));
      },

      changeNumber(increment) {
        let currentValue = parseInt(this.model);
        this.set('model', this.verify(currentValue + increment, this.minValue, this.maxValue));
      },

      keyPressOnNumber(val, event) {
        const reg = /[0-9]/gi;
        if (!event.key.match(reg)) event.preventDefault();
      }

    }
  });

  _exports.default = _default;
});