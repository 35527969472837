define("@beijer/ember-wizard-base/components/wizard-step-base/component", ["exports", "@ember/component", "@ember/object", "@ember/runloop"], function (_exports, _component, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    stepId: null,
    currentStepCompleted: false,
    allowStepToComplete: true,

    init() {
      this._super(...arguments);

      if (this.allowStepToComplete === true) {
        this.allowCurrentStepToComplete(this.allowStepToComplete);
      }
    },

    allowStepToCompleteChanged: (0, _object.observer)('allowStepToComplete', function () {
      this.allowCurrentStepToComplete(this.allowStepToComplete);
    }),
    stepCompleted: (0, _object.observer)('currentStepCompleted', function () {
      if (this.stepId === this.currentStep && this.currentStepCompleted === true) {
        _runloop.run.scheduleOnce('afterRender', this, this.setSteps);
      }
    }),

    setSteps() {
      this.set('currentStepCompleted', false);
      let allowIncrementStep = true;

      if (this.beforeStepCompleted) {
        allowIncrementStep = this.beforeStepCompleted();
      }

      if (allowIncrementStep) {
        this.incrementStep();
      }
    }

  });

  _exports.default = _default;
});