define("@beijer/morpheus-ide-components/components/ide/common/license-dialog/component", ["exports", "@ember/component", "@ember/object/computed", "@beijer/morpheus-ide-components/helpers/bjgui-acceptance-compliant", "@beijer/morpheus-ide-components/components/ide/common/license-dialog/template"], function (_exports, _component, _computed, _bjguiAcceptanceCompliant, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   * @module
   * @description displays a license dialog if the eula version is greater than the accepted
   * @param title - dialog title
   * @param licenseText - license text to display
   * @param eulaVersion - license text version
   * @param eulaVersionAccepted - last license text version accepted by the user
   * @param cancelButtonText - text to display on cancel button
   * @param submitButtonText - text to display on submit button
   * @param onAccept - callback on press ok button
   * @param onDecline - callback on press cancel button
   * @augments ember.Component
   */
  var _default = _component.default.extend(_bjguiAcceptanceCompliant.default, {
    layout: _template.default,
    closeButton: (0, _computed.alias)('openToView'),
    cancelButton: (0, _computed.alias)('openToView'),
    submitButton: (0, _computed.not)('openToView'),
    isEulaAccepted: true,
    openToView: false,
    isEulaNotAccepted: (0, _computed.not)('isEulaAccepted'),
    isOpen: (0, _computed.or)('isEulaNotAccepted', 'openToView'),
    actions: {
      accept() {
        this.onAccept(this);
      },

      decline() {
        this.onDecline(this);
      }

    }
  });

  _exports.default = _default;
});